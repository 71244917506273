:root {
  --jet: #28282a;
  --flame: #cf5c36;
  --cambrigde-blue: #00c2d1;
  --glaucous: #717ec3;
  --pale-dogwood: #e8ddb5;
  --off-white: #ddd;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
}

.messages-container {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: var(--jet);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message-identifier {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.message {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.message.ai {
  justify-content: flex-start;
}

.message-author {
  color: white;
  font-weight: bold;
}

.message-content {
  max-width: 70%;
  padding: 8px 12px;
  border-radius: 18px;
  color: white;
  white-space: pre-wrap;
}

.message.user .message-content {
  background-color: #007bff;
}

.message.ai .message-content {
  background-color: #666;
}

.input-area {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.input-field {
  flex-grow: 1;
  padding: 10px;
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 18px;
  outline: none;
}

.send-button {
  padding: 10px 15px;
  background-color: var(--cambrigde-blue);
  color: white;
  border: none;
  border-radius: 18px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

.copy-button {
  padding: 5px;
  background-color: var(--glaucous);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
